<template>
    <section>
        <header class="mb-2">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <section class="d-flex justify-content-between">
                            <div>
                                <p><strong>No. Invoice</strong></p>
                                <p>{{ pb.no_invoice }}</p>
                            </div>
                            <div>
                                <b-button @click.prevent="$router.push({name: 'penyimpanan', query: {inv: pb.id}})" variant="primary">
                                    <span class="align-middle">Tempatkan Barang</span>
                                    <feather-icon icon="ChevronRightIcon" class="ml-1">
                                    </feather-icon>
                                </b-button>
                            </div>
                        </section>
                    </b-col>
                    <b-col md="3" sm="12">
                        <p><strong>Tanggal Penerimaan</strong></p>
                        <p>{{ pb.tanggal_penerimaan }}</p>
                    </b-col>
                    <b-col md="3" sm="12">
                        <p><strong>Tanggal Invoice</strong></p>
                        <p>{{ pb.tanggal_invoice }}</p>
                    </b-col>
                    <b-col md="6" sm="12">
                        <p><strong>Keterangan</strong></p>
                        <p>{{pb.keterangan ? pb.keterangan : 'kosong'}}</p>
                    </b-col>
                </b-row>
            </b-card>
        </header>

        <main>
            <b-card>
                <h5>Daftar Barang Yang Diterima</h5>
                <b-table striped hover :items="barangs" :fields="barangFields">
                    <template #cell(no)="row">
                        {{ (row.index + 1) }}
                    </template>
                    <template #cell(barang)="{item}">
                        {{ item.barang ? item.barang.nama : '-' }}
                    </template>
                    <template #cell(satuan)="{item}">
                        {{ item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-' }}
                    </template>
                </b-table>
            </b-card>
        </main>
    </section>
</template>
<script>
    import {BRow, BCol, BCard, BButton, BTable} from 'bootstrap-vue'
    export default {
        components: {
            BRow,
            BCol,
            BButton,
            BCard,
            BTable
        },
        data: () => ({
            pb: {
                no_invoice: null,
                tanggal_invoice: null,
                tanggal_penerimaan: null,
                keterangan: null
            },
            barangFields: [
                {key: 'no', label: 'No', sortable: true},
                {key: 'barang', label: 'Nama Barang', sortable: true},
                {key: 'jumlah', label: 'Jumlah', sortable: true},
                {key: 'satuan', label: 'Satuan', sortable: true}
            ],
            barangs: []
        }),
        methods: {
            async getPb() {
                const pb = await this.$store.dispatch('penerimaan-barang/getDataById', this.$route.params.id)
                this.pb = pb
            },
            async getBarangPb() {
                const barangs = await this.$store.dispatch('penerimaan-barang/getDataBarang', {
                    penerimaan_id: this.$route.params.id
                })
                this.barangs = barangs
            }
        },
        async created() {
            this.getPb()
            this.getBarangPb()
        }
    }
</script>