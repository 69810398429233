var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('header', {
    staticClass: "mb-2"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('section', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('p', [_c('strong', [_vm._v("No. Invoice")])]), _c('p', [_vm._v(_vm._s(_vm.pb.no_invoice))])]), _c('div', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push({
          name: 'penyimpanan',
          query: {
            inv: _vm.pb.id
          }
        });
      }
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Tempatkan Barang")]), _c('feather-icon', {
    staticClass: "ml-1",
    attrs: {
      "icon": "ChevronRightIcon"
    }
  })], 1)], 1)])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal Penerimaan")])]), _c('p', [_vm._v(_vm._s(_vm.pb.tanggal_penerimaan))])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal Invoice")])]), _c('p', [_vm._v(_vm._s(_vm.pb.tanggal_invoice))])]), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.pb.keterangan ? _vm.pb.keterangan : 'kosong'))])])], 1)], 1)], 1), _c('main', [_c('b-card', [_c('h5', [_vm._v("Daftar Barang Yang Diterima")]), _c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "items": _vm.barangs,
      "fields": _vm.barangFields
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(barang)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : '-') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-') + " ")];
      }
    }])
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }